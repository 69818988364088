import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from './OnepageMobileMenu';
import OnepageMenuItems from './OnepageMenuItems';
import CanvasMenu from './CanvasMenu';

import Logo from '../../../assets/img/logo/logo.webp';

const Header = (props) => {
	const { headerClass, offCanvas } = props;
	const { item1, item2, item3, item4, item5, item6 } = props;

	const [menuOpen, setMenuOpen] = useState(false)

	const [isVisible, setIsVisible] = useState(false);
	useEffect(() => {
		// Sticky is displayed after scrolling for 100 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	const canvasMenuAdd = () => {
		document.body.classList.add('nav-expanded');
	};


	return (
		<React.Fragment>
			<div className="full-width-header">
				<header id="header" className={headerClass ? headerClass : 'header style3 header-transparent'}>
					<div className={isVisible ? 'menu-area menu-sticky sticky' : 'menu-area menu-sticky'}>
						<div className="container-fluid">
							<div className="row align-items-center">
								<div className="col-lg-3">
									<div className="logo-area hidden-md">
										<Link to="/" as="/">
											<img src={Logo} alt="logo" />
										</Link>
									</div>
								</div>
								<div className="col-lg-9 text-right md-text-left">
									<div className="menu-area">
										<div className="main-menu">
											<div className="mobile-menu fixed-width md-display-block">
												<Link to="/" className="logo"><img src={Logo} alt="logo" /></Link>
												<Link to="#" className="menu-toggle primary" onClick={() => {
													setMenuOpen(!menuOpen)
												}}>
													<i className="fa fa-bars"></i>
												</Link>
											</div>
											<nav className="hd-menu pr-60 lg-pr-50 md-pr-0 hidden-md">
												<ul className="nav-menu">
													<OnepageMenuItems
														menuItem1={item1 ? item1 : 'Home'}
														menuItem2={item2 ? item2 : 'Office Walls'}
														menuItem3={item3 ? item3 : 'School Walls'}
														menuItem4={item4 ? item4 : 'Home Walls'}
														menuItem5={item5 ? item5 : 'Application'}
														menuItem6={item6 ? item6 : 'Contact'}
													/>
												</ul>
											</nav>
										</div>
										<div className="expand-btn-inner hidden-md">
											{
												offCanvas ?
													<ul>

														<li>
															<a href="#" onClick={canvasMenuAdd} id="nav-expander" className="humburger nav-expander">
																<span className="dot1"></span>
																<span className="dot2"></span>
																<span className="dot3"></span>
																<span className="dot4"></span>
																<span className="dot5"></span>
																<span className="dot6"></span>
																<span className="dot7"></span>
																<span className="dot8"></span>
																<span className="dot9"></span>
															</a>
														</li>
													</ul> :
													<ul>

													</ul>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<MobileMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
					<div onClick={() => setMenuOpen(false)} className={menuOpen ? "body-overlay show" : "body-overlay"}></div>
				</header>
				<CanvasMenu />
			</div>
		</React.Fragment>
	);
}

export default Header;