import React from 'react';
import OnepageMenuItems from '../../components//Layout/Header/OnepageMenuItems';

import SectionTitle from '../../components/Common/SectionTitle';

import aboutImg from '../../assets/img/school/main.webp'

const About = (props) => {
    const { item6 } = props;

    return (
        <div id="school" className="school bg17 pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 pl-60 md-pl-14">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style2"
                            subtitle="Make your school walls interactive"
                            titleClass="title pb-38"
                            title="Create a positive learning environment"
                            descClass="desc pb-36"
                            description="A classroom should be decorated with visual aids that encourage learning and reinforce skills. However, that doesn't mean that the classroom decor cannot include some decorations that are simply colorful and fun to look at. We print at interior and exterior walls that are not easily scratched like vinyl murals. Even our exterior printing has UV protection that lasts at least 5 years."
                            secondDescClass="margin-0 pb-16"
                            secondDescription="Wall Printing can be easely removed by simple painting unlike the murals and decals or reprinted over the old one. These procedures are cheaper than murals and decals we can guarantine and we are always ready to remove our printing or reprint over it at your walls."
                            thirdDescClass="margin-0 pb-16"
                            thirdDescription="Inspirational and informative wall art in schools is a great way to raise your pupils’ aspirations and raise a smile. Reception areas, dull classrooms, uninspiring libraries, drab offices and bland corridors can be brighten up with our bespoke wall graphics for schools."
                        />
                        {/* Section Title End */}
                        <ul className="nav-menu readon get-started text-left">
                            <OnepageMenuItems
                                menuItem6={item6 ? item6 : 'Get Started'}
                            />
                        </ul>
                    </div>
                    <div className="col-lg-6 md-mb-50">
                        <div className="images">
                            <img src={aboutImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;