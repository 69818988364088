import React from 'react';
import { Link } from 'react-router-dom';

const SingleProject = (props) => {
	return(
        <div className={props.itemClass}>
            <div className="project-img">
                
                <img 
                    src={props.projectImage} 
                    alt={props.Title}
                />
                
            </div>
            <div className="project-content">
                <h3 className="title">
                    
                    {props.Title}
                    
                </h3>
                <span className="category">                    
                    {props.Category}
                </span>
            </div>
        </div>
	)
}

export default SingleProject