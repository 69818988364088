import React from 'react';
import OnepageMenuItems from '../../components//Layout/Header/OnepageMenuItems';

import SectionTitle from '../../components/Common/SectionTitle';

import aboutImg from '../../assets/img/materials/3.webp'
import aboutImg2 from '../../assets/img/materials/4.webp'

const Application = (props) => {
    const { item6 } = props;
    return (
        <div id="application" className="about bg17 pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 pl-60 md-pl-14">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style2"
                            subtitle="High Resolution Indoor and Outdoor Wall Art"
                            titleClass="title pb-38"
                            title="Any Custom Image on Any Material with High Resolution"
                            descClass="desc pb-36"
                            description="Wall Printing directly prints any image and words on any wall in high resolution. Or Wall Printing can print any image on wall murals, decals of various materials then install them at your walls." 
                            secondDescClass="margin-0 pb-16"
                            secondDescription="Qualities of prints: Waterproof, Sunproof, Bright Color, Long Lasting Color Fixation. No fading for 5 years outdoor ones, No fading for 10 years indoor ones. Immediate Drying which means no hindrance for other works during and immediately after printing."
                            
                        />
                        
                    </div>
                    <div className="col-lg-6 md-mb-50">
                        <div className="images">
                            <img src={aboutImg} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        {/* Section Title Start */}
                        <SectionTitle
                            descClass="desc pb-36"
                            description="It is widely asked for advertisement and decoration in offices, schools, kindergarten, shopping mall, bedroom, parlour, bathroom & at outdoor walls. "
                            secondDescClass="margin-0 pb-16"
                            secondDescription="The prints at direct walls can not be washed out however they can be removed by simply painting on them or by reprinting over them, unlike the murals and decals."
                            thirdDescClass="margin-0 pb-16"
                            thirdDescription="Wall Printing can be easely removed by simple painting unlike the murals and decals or reprinted over the old one. These procedures are cheaper than murals and decals we can guarantine and we are always ready to remove our printing or reprint over it at your walls."
                        />
                        {/* Section Title End */}
                        <ul className="nav-menu readon get-started text-left">
                            <OnepageMenuItems									
                                menuItem6={item6 ? item6 : 'Get Started'}
                            />
                        </ul>
                    </div>
                    <div className="col-lg-6 md-mb-50">
                        <div className="images">
                            <img src={aboutImg2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Application;