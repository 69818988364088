import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProject from '../../components/Project/SingleProject';


import projectImg1 from '../../assets/img/home/1.webp';
import projectImg2 from '../../assets/img/home/2.webp';
import projectImg3 from '../../assets/img/home/3.webp';
import projectImg4 from '../../assets/img/home/4.webp';
import projectImg5 from '../../assets/img/home/5.webp';
import projectImg6 from '../../assets/img/home/6.webp';
import projectImg7 from '../../assets/img/home/7.webp';
import projectImg8 from '../../assets/img/home/8.webp';
import projectImg9 from '../../assets/img/home/9.webp';
import projectImg10 from '../../assets/img/home/10.webp';
import projectImg11 from '../../assets/img/home/11.webp';
import projectImg12 from '../../assets/img/home/12.webp';
import projectImg13 from '../../assets/img/home/13.webp';
import projectImg14 from '../../assets/img/home/14.webp';
import projectImg15 from '../../assets/img/home/15.webp';
import projectImg16 from '../../assets/img/home/16.webp';
import projectImg17 from '../../assets/img/home/17.webp';
import projectImg18 from '../../assets/img/home/18.webp';
import projectImg19 from '../../assets/img/home/19.webp';
import projectImg20 from '../../assets/img/home/20.webp';
import projectImg21 from '../../assets/img/home/21.webp';
import projectImg22 from '../../assets/img/home/22.webp';
import projectImg23 from '../../assets/img/home/23.webp';
import projectImg24 from '../../assets/img/home/24.webp';
import projectImg242 from '../../assets/img/home/24-2.webp';
import projectImg25 from '../../assets/img/home/25.webp';
import projectImg26 from '../../assets/img/home/26.webp';
import projectImg27 from '../../assets/img/home/27.webp';
import projectImg28 from '../../assets/img/home/28.webp';
import projectImg29 from '../../assets/img/home/29.webp';
import projectImg30 from '../../assets/img/home/30.webp';
import projectImg31 from '../../assets/img/home/31.webp';
import projectImg32 from '../../assets/img/home/32.webp';
import projectImg33 from '../../assets/img/home/33.webp';
import projectImg34 from '../../assets/img/home/34.webp';
import projectImg35 from '../../assets/img/home/35.webp';
import projectImg36 from '../../assets/img/home/36.webp';
import projectImg37 from '../../assets/img/home/37.webp';
import projectImg38 from '../../assets/img/home/38.webp';


const Project = () => {

    const options = {
        items: 3,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: true,
            },
            768: {
                items: 2,
                stagePadding: 0,
                nav: true,
            },
            992: {
                items: 2,
                stagePadding: 0,
            },
            1200: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

    return (
        <React.Fragment>
            <div id="portfolio" className="project style2 bg1 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg white-color"
                        subtitle="Home Wall Printing Ideas"
                        titleClass="title title2 white-color"
                        title="Select any idea or let us consult your custom idea"
                    />
                    <OwlCarousel options={options} >
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg1}
                            Title="Choose Me For Your"
                            Category="Home"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg2}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg3}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg4}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg5}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg6}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg7}
                            Title="Choose Me For Your"
                            Category="Home"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg8}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg9}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg10}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg11}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg12}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg13}
                            Title="Choose Me For Your"
                            Category="Home"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg14}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg15}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg16}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg17}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg18}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg19}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg20}
                            Title="Choose Me For Your"
                            Category="Home"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg21}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg22}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg23}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg24}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg242}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                    </OwlCarousel>
                </div>
                <div className="container mt-40">
                    <OwlCarousel options={options}>
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg25}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg26}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg27}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg28}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg29}
                            Title="Choose Me For Your"
                            Category="Home"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg30}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg31}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg32}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg33}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg34}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg35}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg36}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg37}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg38}
                            Title="Choose Me For Your"
                            Category="Home"

                        />
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Project