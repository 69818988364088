//src/firebase.js

//Import firebase from node_modules
import firebase from 'firebase';
//import { initializeApp } from "firebase"

//you have copy and paste this config from your Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyAQi_N05VlM1T7Uhig6gye4ZmE5bgXdm0M",
  authDomain: "wallprinting.firebaseapp.com",
  projectId: "wallprinting",
  region: "us-central1",
  storageBucket: "wallprinting.appspot.com",
  messagingSenderId: "71914241686",
  appId: "1:71914241686:web:f76a978d6b5bf5b5c9ccb7",
  measurementId: "G-PTFGFDRZ91"
};

//initialize a Firebase instance
firebase.initializeApp(firebaseConfig);

//initialize Cloud Functions through Firebase
firebase.functions();

//export the `firebase` namespace to import it in src/App.js
export default firebase;