import React from "react";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import Brand from '../../components/Common/Brand';





const FaqMain = () => {

    return (
        <React.Fragment>
            {/* Faq Part Start */}
            <div className="faq gray-bg pt-120 md-pb-394 pb-434 md-pt-90 md-pb-90">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-6">
                            <SectionTitle
                                sectionClass="sec-title2 mb-44"
                                subtitleClass="sub-text style-bg"
                                subtitle="Faqs"
                                titleClass="title title6"
                                title="Do You Have Any Questions?"
                            />
                            <Accordion className="accodion-style1" preExpanded={'b'}>
                                <AccordionItem uuid="a">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Where should I incorporate my business?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Impress clients new and existing with elite construction brochures. Impress clients.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="b">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Where should I incorporate my business?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Impress clients new and existing with elite construction brochures. Impress clients.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="c">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            How can I safely use cleaning chemicals?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Impress clients new and existing with elite construction brochures. Impress clients.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem uuid="d">
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            What type of company is measured?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Impress clients new and existing with elite construction brochures. Impress clients.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            {/* Faq Part End */}

            {/* Contact Part Start */}
            <div className="contact style2 mt--314">
                <div className="container">
                    <div className="contact-box-wrap">
                        <SectionTitle
                            sectionClass="sec-title2 text-center mb-34"
                            subtitleClass="sub-text style-bg con-bg white-color"
                            subtitle="Contact"
                            titleClass="title testi-title white-color"
                            title="Request Free Consultation"
                        />
                        <ContactForm />
                    </div>
                </div>
            </div>
            {/* Contact Part End */}

            {/* Pricing Part Start */}
            <div className="pricing style2 pt-120 pb-142 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text style-bg"
                        subtitle="Pricing"
                        titleClass="title title2"
                        title="Our Pricing Plan"
                    />
                </div>
            </div>
            {/* Pricing Part End */}

            {/* brand-area-start */}
            <Brand />
            {/* brand-area-end */}
        </React.Fragment>
    )
}

export default FaqMain;





