import React, { useState, useEffect } from 'react';
import OnepageMenuItems from '../Layout/Header/OnepageMenuItems';

import bgImg from '../../assets/img/bg/banner2.webp';

const bgStyle = {
    backgroundImage: `url(${bgImg})`,
    position: 'relative',
    height: '85vh',
    width: '100%',

}

const BannerHome = (props) => {
    const { item7 } = props;

    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        // Sticky is displayed after scrolling for 100 pixels
        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <React.Fragment>

            {/* <!-- banner section start --> */}

            <div className="banner stylish" style={bgStyle} >
                <div className="container">
                    <div className="banner-content">
                        <span className="sub-title zIndex2 text-left">Wall Art for OFFICE, SCHOOL, & HOME WALLS.</span>
                        <div className="title zIndex2 text-left">We print</div>
                        <div className="zIndex2 ">                            
                            <li className="list">2D and 3D images</li>
                            <li className="list">at or for any wall</li>
                            <li className="list">wherever you have them</li>                          
                        </div>
                        <p className="desc zIndex2 text-left">The Wall Printing is one of New Zealand's most professional & affordable wall printers.</p>
                        <ul className="nav-menu readon border text-left">
                            <OnepageMenuItems
                                menuItem7={item7 ? item7 : 'Learn More'}
                            />
                        </ul>
                    </div>
                </div>

            </div>
            {/* <!-- banner section end --> */}

        </React.Fragment>
    );
}

export default BannerHome;