import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProject from '../../components/Project/SingleProject';


import projectImg1 from '../../assets/img/office/1.webp';
import projectImg2 from '../../assets/img/office/2.webp';
import projectImg3 from '../../assets/img/office/3.webp';
import projectImg4 from '../../assets/img/office/4.webp';
import projectImg5 from '../../assets/img/office/5.webp';
import projectImg6 from '../../assets/img/office/6.webp';
import projectImg7 from '../../assets/img/office/7.webp';
import projectImg8 from '../../assets/img/office/8.webp';
import projectImg9 from '../../assets/img/office/9.webp';
import projectImg10 from '../../assets/img/office/10.webp';
import projectImg11 from '../../assets/img/office/11.webp';
import projectImg12 from '../../assets/img/office/12.webp';
import projectImg13 from '../../assets/img/office/13.webp';

const Project = () => {

    const options = {
        items: 3,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: true,
            },
            768: {
                items: 2,
                stagePadding: 0,
                nav: true,
            },
            992: {
                items: 2,
                stagePadding: 0,
            },
            1200: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

    return (
        <React.Fragment>
            <div id="portfolio" className="project style2 bg1 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg white-color"
                        subtitle="Office Wall Design Ideas"
                        titleClass="title title2 white-color"
                        title="Select any idea or let us consult your unique idea"
                    />
                    <OwlCarousel options={options} >
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg1}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg2}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg3}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg4}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg5}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg6}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg7}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg8}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg9}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg10}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg11}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg12}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                        <SingleProject
                            itemClass="project-item"
                            projectImage={projectImg13}
                            Title="Choose Me For Your"
                            Category="Office"


                        />
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Project