import React from 'react';
import SinglePost from './SinglePost';


import blogImg1 from '../../assets/img/blog/1.webp';
import blogImg2 from '../../assets/img/blog/2.webp';
import blogImg3 from '../../assets/img/blog/3.webp';

const Service = () => {

    return (
        <div id="u-services" className="u-services main-home style1 pt-100 md-pt-70">
            <div className="container">
                <div className="row justify-content-lg-between" >
                    <div className="col-lg-3 justify-content-lg-evenly ml--30 col-md-6 md-ml-40 md-mr-40 md-mt--150 md-mb-30">
                        <SinglePost
                            blogClass='services-item'
                            blogImage={blogImg1}
                            blogCategory2='Office Walls'
                            blogDesc='Need custom office wall design?'
                        />
                    </div>
                    <div className="col-lg-3 justify-content-lg-evenly col-md-6 md-ml-40 md-mr-40 md-mb-30">
                        <SinglePost
                            blogClass='services-item'
                            blogImage={blogImg2}
                            blogCategory3='School Walls'
                            blogDesc='Custom school wall printers'
                        />
                    </div>
                    <div className="col-lg-3 justify-content-lg-evenly mr-50 col-md-6 md-ml-40 md-mr-40 md-mb-30">
                        <SinglePost
                            blogClass='services-item'
                            blogImage={blogImg3}
                            blogCategory4='Home Walls'
                            blogDesc='Desire a change at home with wall prints?'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;