import React from 'react';
import { Link } from 'react-router-dom';
import OnepageMenuItems from '../../components/Layout/Header/OnepageMenuItems';

const SinglePost = ( props ) => {
    const {blogClass, blogImage, blogTitle, blogDesc, blogCategory2, blogCategory3, blogCategory4, blogButtonClass, blogButtonText } = props;
    

    return (
        <div className={blogClass ? blogClass : 'blog-item mb-44'}>
            <div className="image-wrap">
                <ul>
                    <img
                        src={blogImage}
                        alt={blogTitle}
                    />
                    
                </ul>
                {/*<Link to="/blog/blog-details">
                    <img
                        src={blogImage}
                        alt={blogTitle}
                    />
                </Link>*/}
                <ul className="readon get-started ext-con" >
                    
                        <OnepageMenuItems 									
                            menuItem2={blogCategory2 ? blogCategory2 : ''}
                        />
                        <OnepageMenuItems									
                            menuItem3={blogCategory3 ? blogCategory3 : ''}
                        />
                        <OnepageMenuItems									
                            menuItem4={blogCategory4 ? blogCategory4 : ''}
                        />
                    
                </ul>
            </div>
            <div className="blog-content">
                <h3 className="blog-title">
                    <Link to="/blog/blog-details">
                        {blogTitle ? blogTitle : ''}
                    </Link>
                </h3>
                <p className="desc">{blogDesc ? blogDesc : 'We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by the charms of pleasure of the moment, so blinded by desire, that...'}</p>
                <div className={blogButtonClass ? blogButtonClass : 'blog-button'}>
                    <Link to="/blog/blog-details">
                        {blogButtonText ? blogButtonText : ''}
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default SinglePost