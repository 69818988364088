import React from 'react';
import { Link } from 'react-router-dom';
import OnepageMenuItems from '../Header/OnepageMenuItems';

const FooterBottom = (props) => {
    const { item1, item5, item6, item7 } = props;

    return (
        <div className="footer-bottom">
            <div className="container-footer-bottom">
                <div className="row y-middle">
                    <div className="col-lg-6 text-right md-text-center md-mb-10 order-last">
                        <ul className="hd-menu nav-menu copy-right-menu">
                            <OnepageMenuItems
                                menuItem1={item1 ? item1 : 'Home'}
                                menuItem7={item7 ? item7 : 'Services'}
                                menuItem5={item5 ? item5 : 'How We Work'}
                                menuItem6={item6 ? item6 : 'Contact'}
                            />
                            <li><Link to="#">FAQs</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <div className="copyright text-start md-mb-10">
                            <p>© {new Date().getFullYear()} <Link to="/"> WallPrinting™. </Link> All Rights Reserved. </p>
                            <p className='text-info'>Crafted by <span>
                                <a href="https://www.intellapps.io/" target="_blank" rel="noreferrer">
                                    IntellApps™.
                                </a></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;