import React from 'react';
import { Route, BrowserRouter as Router, Switch, Link, withRouter } from 'react-router-dom';



//Custom Components

import Home from '../pages/home';
import Office from '../pages/home/Office';
import School from '../pages/home/School';
import HomeWalls from '../pages/home/Home Walls';
import Application from '../pages/home/Application';
import Contact from '../pages/contact';
import Service from '../pages/service';

import Faq from '../pages/faq';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'


const App = () => {
    return (
        <div className='App'>
            <Router>  
                <LoadTop />          
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />
                    <Route path="/home/Home Walls" component={HomeWalls} />
                    <Route path="/home/Office" component={Office} />
                    <Route path="/home/School" component={School} />
                    <Route path="/home/Application" component={Application} />
                    <Route path="/service" component={Service} />

                    <Route path="/contact" component={Contact} />
                    <Route path="/faq" component={Faq} />
                    <Route component={Error} />
                </Switch>
            </Router>
            
        </div>
    );
}


export default App;
