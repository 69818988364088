import React from 'react';
import Header from '../components/Layout/Header/Header';
import Footer from '../components/Layout/Footer';
import ScrollToTop from '../components/Common/ScrollTop';
import FaqMain from '../components/Faq';



const Faq = () => {

    return (
        <React.Fragment>
            <Header
                parentMenu='page'
                secondParentMenu='pages'
                activeMenu='/faq'
            />
            <div>
            

                {/* Faq */}
                <FaqMain />
                {/* Faq */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </div>
            <Footer />
        </React.Fragment>

    );
}


export default Faq;