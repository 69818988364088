import React from 'react';
import BannerHome from '../../components/Banner/BannerHome';
import Service from '../service';
import Office from './Office';
import School from './School';
import HomeWalls from './Home Walls';
import ProjectOne from './ProjectSectionOne';
import ProjectTwo from './ProjectSectionTwo';
import ProjectThree from './ProjectSectionThree';
import Application from './Application';
import Contact from '../contact';
import Brand from '../../components/Common/Brand';
import ScrollToTop from '../../components/Common/ScrollTop';


const HomeMain = () => {
	return (
		<React.Fragment>
			{/* banner-start */}
			<BannerHome />
			{/* banner-end */}

			{/* ServiceTwo-area-start */}
			<Service />
			{/* ServiceTwo-area-end */}

			{/* About-area-start */}
			<Office />
			{/* About-area-end */}

			{/* project-area-start */}
			<ProjectOne />
			{/* project-area-end */}

			{/* About-area-start */}
			<School />
			{/* About-area-end */}

			{/* project-area-start */}
			<ProjectTwo />
			{/* project-area-end */}

			{/* HomeWalls-area-start */}
			<HomeWalls />
			{/* HomeWalls-area-end */}

			{/* project-area-start */}
			<ProjectThree />
			{/* project-area-end */}

			{/* Application-area-start */}
			<Application />
			{/* Application-area-end */}

			{/* Contact-area-start */}
			<Contact />
			{/* Contact-area-end */}

			{/* brand-area-start */}
			<Brand 
				brandClass="partner style5 pt-70 pb-70"
			/>
			{/* brand-area-end */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeMain;