import React, { useState } from 'react';

const OnepageMenuItems = (props) => {
    const { menuItem1, menuItem2, menuItem3, menuItem4, menuItem5, menuItem6, menuItem7 } = props;

    const [home, setHome] = useState(true)
    const [office, setOffice] = useState(false)
    const [school, setSchool] = useState(false)
    const [homew, setHomeWalls] = useState(false)
    const [application, setApplication] = useState(false)
    const [contact, setContact] = useState(false)

    const [service, setService] = useState(false)

    const addActiveClass = (menu) => {

        if (menu === 'home') {
            setHome(!home)
            setOffice(false)
            setSchool(false)
            setHomeWalls(false)
            setApplication(false)
            setContact(false)
            setService(false)
        }
        else if (menu === './Office') {
            setHome(false)
            setOffice(!home)
            setSchool(false)
            setHomeWalls(false)
            setApplication(false)
            setContact(false)
            setService(false)
        }
        else if (menu === './School') {
            setHome(false)
            setOffice(false)
            setSchool(!home)
            setHomeWalls(false)
            setApplication(false)
            setContact(false)
            setService(false)
        }
        else if (menu === './Home Walls') {
            setHome(false)
            setOffice(false)
            setSchool(false)
            setHomeWalls(!home)
            setApplication(false)
            setContact(false)
            setService(false)
        }
        else if (menu === './Application') {
            setHome(false)
            setOffice(false)
            setSchool(false)
            setHomeWalls(false)
            setApplication(!home)
            setContact(false)
            setService(false)
        }
        else if (menu === '../contact') {
            setHome(false)
            setOffice(false)
            setSchool(false)
            setHomeWalls(false)
            setApplication(false)
            setContact(!home)
            setService(false)
        }
        else if (menu === '../service') {
            setHome(false)
            setOffice(false)
            setSchool(false)
            setHomeWalls(false)
            setApplication(false)
            setContact(false)
            setService(!home)
        }
    };

    return (
        <React.Fragment>
            {
                menuItem1 ?
                    <li className={home ? "current-menu-item" : ""}>
                        <a onClick={() => { addActiveClass('home'); document.getElementById("header").scrollIntoView({ behavior: 'smooth' }) }} >
                            {menuItem1}
                        </a>
                    </li> : ''
            }
            {
                menuItem2 ?
                    <li className={office ? "current-menu-item" : ""}>
                        <a onClick={() => { addActiveClass('office'); document.getElementById("office").scrollIntoView({ behavior: 'smooth' }); }} >
                            {menuItem2}
                        </a>
                    </li> : ''
            }

            {
                menuItem3 ?
                    <li className={school ? "current-menu-item" : ""}>
                        <a onClick={() => { addActiveClass('school'); document.getElementById("school").scrollIntoView({ behavior: 'smooth' }); }} >
                            {menuItem3}
                        </a>
                    </li> : ''
            }

            {
                menuItem4 ?
                    <li className={homew ? "current-menu-item" : ""}>
                        <a onClick={() => { addActiveClass('homew'); document.getElementById("homew").scrollIntoView({ behavior: 'smooth' }); }} >
                            {menuItem4}
                        </a>
                    </li> : ''
            }

            {
                menuItem5 ?
                    <li className={application ? "current-menu-item" : ""}>
                        <a onClick={() => { addActiveClass('application'); document.getElementById("application").scrollIntoView({ behavior: 'smooth' }); }} >
                            {menuItem5}
                        </a>
                    </li> : ''
            }

            {
                menuItem6 ?
                    <li className={contact ? "current-menu-item" : ""}>
                        <a onClick={() => { addActiveClass('contact'); document.getElementById("contact").scrollIntoView({ behavior: 'smooth' }); }} >
                            {menuItem6}
                        </a>
                    </li> : ''
            }

            {
                menuItem7 ?
                    <li className={service ? "current-menu-item" : ""}>
                        <a onClick={() => { addActiveClass('service'); document.getElementById("u-services").scrollIntoView({ behavior: 'smooth' }); }} >
                            {menuItem7}
                        </a>
                    </li> : ''
            }

        </React.Fragment>
    );
}

export default OnepageMenuItems;