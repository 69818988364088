import React from 'react';
import OnepageMenuItems from '../../components//Layout/Header/OnepageMenuItems';

import SectionTitle from '../../components/Common/SectionTitle';

import aboutImg from '../../assets/img/home/main.webp'

const Homew = (props) => {
    const { item6 } = props;

    return (
        <div id="homew" className="about bg17 pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                        <div className="images">
                            <img src={aboutImg} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style2"
                            subtitle="Transform Your Home Walls"
                            titleClass="title pb-38"
                            title="Changing Your Home Walls Has Never Been So Easy!"
                            descClass="desc pb-36"
                            description="Whether you want a room refresh or a total space transformation, Wall Printing is a simple and stylish way to update your home wall decor. We print your custom image (or select from our catalogue) in any custom size at your home walls of any material - interior & exterior."
                            secondDescClass="margin-0 pb-16"
                            secondDescription="Feel the rush and the adrenaline pumping through your veins and be ready for the biggest game of your life with our range of sensational Wall Printings. Get revved up because we can print at your walls any image you want to have. Unlike wall murals we can print any image with and without background."
                            thirdDescClass="margin-0 pb-16"
                            thirdDescription=""
                        />
                        {/* Section Title End */}
                        <ul className="nav-menu readon get-started text-left">
                            <OnepageMenuItems									
                                menuItem6={item6 ? item6 : 'Get Started'}
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homew;