import React from 'react';
import OnepageMenuItems from '../../components//Layout/Header/OnepageMenuItems';
import SectionTitle from '../../components/Common/SectionTitle';

import aboutImg from '../../assets/img/office/1.webp'

const Office = (props) => {
    const { item6 } = props;

    return (
        <div id="office" className="about bg17 pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                        <div className="images">
                            <img src={aboutImg} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-30"
                            subtitleClass="sub-text style2"
                            subtitle="DON’T MISS the right office walls"
                            titleClass="title pb-38"
                            title="Wall Printing for Your Office?"
                            descClass="desc pb-36"
                            description="Why not? Now with us, it is more than affordable. We print at vertical walls of any material, at any mounted parts of your office. No need to dismount them, no need to block any other works during printing. Our Wall Printing is fast & the prints dry immediately."
                            secondDescClass="margin-0 pb-16"
                            secondDescription="Running a company is not as simple as it seems. One of the most important factors to consider here is employee productivity, which involves a number of aspects that have a huge effect on it. What the eyes can see can either have a positive or negative effect on a person’s brain activity, and adding a bit of color to your office can benefit your employees."
                            thirdDescClass="margin-0 pb-16"
                            thirdDescription="A study done by the University of Massachusetts found that adding color to your office and changing it often helps employees by reducing stress, increasing creativity, enhancing morale, broadening their appreciation of diversity, and encouraging discussions and expressions of opinions."
                        />
                        {/* Section Title End */}
                        <ul className="nav-menu readon get-started text-left">
                            <OnepageMenuItems
                                menuItem6={item6 ? item6 : 'Get Started'}
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Office;