import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';
import footerBg from '../../../assets/img/bg/footer-bg.webp';
import footerLogo1 from '../../../assets/img/logo/logo.webp';
import OnepageMenuItems from '../Header/OnepageMenuItems';

const bgStyle = {
    backgroundImage: `url(${footerBg})`,
    position: 'relative',
    width: '100%',

}

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    const { item1, item2, item3, item4, item5, item6 } = props;
    return (
        <footer className={footerClass ? footerClass : 'footer'}>


            <div className="footer-top" style={bgStyle}>
                <div className="container-fluid text-center">
                    <div className="pt-30">
                        <div className="footer-logo pb-30">
                            <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                        </div>
                        <ul className="hd-menu nav-menu site-map text-center">
                            <OnepageMenuItems
                                menuItem1={item1 ? item1 : 'Home'}
                                menuItem2={item2 ? item2 : 'Office Walls'}
                                menuItem3={item3 ? item3 : 'School Walls'}
                                menuItem4={item4 ? item4 : 'Home Walls'}
                                menuItem5={item5 ? item5 : 'Application'}
                                menuItem6={item6 ? item6 : 'Contact'}
                            />
                        </ul>

                    </div>
                    <div className="pt-30 pb-30">
                        <ul className="footer-social md-mb-30">
                            <li>
                                <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href="https://twitter.com" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a href="https://www.pinterest.com/" target="_blank" rel="noreferrer"><i className="fa fa-pinterest"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>



            <FooterBottom />
        </footer>
    );
}

export default Footer;