import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'contact-box'} >
            <SectionTitle
                sectionClass="sec-title mb-44"
                subtitleClass="sub-text new-text white-color"
                subtitle="Do you have an idea?"
                titleClass="title white-color"
                title="Now We Can Consult Your Project On The Spot or Online"
            />
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-phone"></i>
                </div>
                <div className="address-text">
                    <span className="label">Phone:</span>
                    <a href="tel:022 473 6622">022 473 6622</a>
                </div>
            </div>
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-home"></i>
                </div>
                <div className="address-text">
                    <span className="label">Email:</span>
                    <a href="mailto:consult@wallprinting.co.nz">consult@wallprinting.co.nz</a>
                </div>
            </div>
            <div className="address-box">
                <div className="address-icon">
                    <i className="fa fa-map-marker"></i>
                </div>
                <div className="address-text">
                    <span className="label">Address:</span>
                    <div className="desc">44 Gifford Avenue, Mt Roskill, Auckland 1041, New Zealand</div>
                </div>
            </div>
        </div>
    );

}

export default ContactInfo;