import React from 'react';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer';
import HomeMain from './HomeMain';

import footerWhiteLogo from '../../assets/img/logo/white-logo.webp';

const HomeEleven = () => {
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='home'
                secondParentMenu='multipage'
                headerClass='header style3 modify1 header-transparent bg-white'
                offCanvas='enable'
                activeMenu='/home'
            />
            <HomeMain />
            <Footer
                footerLogo={footerWhiteLogo}
                footerClass="footer style2 modify1 black-dark"
            />
        </React.Fragment>
    );
}


export default HomeEleven;